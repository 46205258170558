import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal, Card ,  Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';
import { cloneDeep, isEmpty } from 'lodash';

import "./index.css";
class BulkLocationAddition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            sizePerPage: 10,
            selectedRows: [],
            confirmBulkAdd: false,
            confirmAddAll: false,
            groupList : [],
            groupId1 : "",
            userGroupList : [],
            allUsersList : [],
            locationList : [],
            locationId : '',
            userLocationList : []
        }
    }
    componentDidMount() {
        this.getLocation();
        this.getAllUsers();
    }


    getLocation = () => {
            const apiPath = this.props.app.apiPath;
       
            Fetcher.get(apiPath + '/api/obd/locations')
                .then(res => {
                    this.setState({
                        locationList: res
                    })
                })
                .catch(err => { console.log('Error in fetching Location List', err) });
    }

    getAllUsers = () => {
                const apiPath = this.props.app.apiPath;
        
                Fetcher.get(apiPath + '/api/obd/all/user/list')
                    .then(res => {
                        this.setState({
                            allUsersList: res
                        })
                    })
                    .catch(err => { console.log('Error in fetching User List', err) });
    }

    setvalue = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }


    confirmBulkAdd = (status) => {
        this.setState({
            confirmBulkAdd: status,
            confirmApprove: false,
            confirmReject: false,
            showListenModal: false
        });
    }

    confirmAddAll = (status) => {
        this.setState({
            confirmAddAll: status,
            confirmApprove: false,
            confirmReject: false,
            showListenModal: false
        });
    }


    filterLocation = (event) => {
        
        event.preventDefault();
        if(this.state.locationId === ''){
            Notify.notify("Please Select Location");
            return 
        }

        const data = {
                "locationId" : this.state.locationId
        }

        //this.state.voicelist = [];
        return Fetcher.post(this.props.app.apiPath + '/api/obd/user/location/list', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
                        .then(res => {
                                this.setState({
                                    userLocationList : res,
                                })
                        })
                        .catch(err => {
                            console.log('Error in Fetching User Location', err);
                            Notify.notify('Error in Fetching User Location');
        });
    }


    handleSizePerPageChange = (newSizePerPage) => {
        this.setState({ sizePerPage: newSizePerPage });
        Cookies.set('voiceFileListsizePerPage', newSizePerPage);
    }


    handleSelectRow = (row, isSelect) => {
        this.setState(prevState => {
               const selectedRows = [...prevState.selectedRows];
               if (isSelect) {
                            selectedRows.push(row.userId);
               } else {
                        const index = selectedRows.indexOf(row.userId);
                        if (index > -1) {
                                    selectedRows.splice(index, 1);
                        }
                }
        return { selectedRows };
       });

    };



    bulkAdd = () => {
        console.log("selectd : "+this.state.selectedRows);
        this.confirmBulkAdd(true);

    }

    allAdd = () => {
        this.confirmAddAll(true);
    }

    addAll = () => {
    
            let a = this.state.allUsersList.map(p => p.userId);
    
            console.log("------------- : "+a);
    
            if(this.state.locationId === ''){
                 Notify.notify("Please Select location");
                 return;
            }

            if(a.toString().length===0){
                Notify.notify("Please Select Atleast Record");
                return;
            }
            const data = {
                "locationId": this.state.locationId,
                "userIds":a.toString()
            }
    
            return Fetcher.post(this.props.app.apiPath + '/api/obd/bulk/user/location/addition', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
                .then(res => {
                    Notify.notify(res.message);
                    this.state.selectedRows= [];
                    this.state.locationId="";
                    setTimeout(() => {
                            window.location.reload(false);
                    }, 1000);
                })
                .catch(err => {
                    console.log('Error in Adding Users Location', err);
                });
    }
     
      isSelectedAll = () => {
      const { allUsersList, selectedRows } = this.state;
      return allUsersList.length > 0 && allUsersList.every(user => selectedRows.includes(user.userId));
       };
   
     handleSelectAll = (isChecked) => {
     const { allUsersList } = this.state;
     const selectedRows = isChecked ? allUsersList.map(user => user.userId) : [];
     this.setState({ selectedRows });
      };
  

    addBulk = () => {
    
            let a = this.state.selectedRows.toString();
    
            console.log("------------- : "+a);

            console.log(">>>>>>>>>> "+this.state.locationId);

            if(this.state.locationId === ""){
                Notify.notify("Please Select Location");
                return;
            }
    
            if(a.toString().length===0){
                Notify.notify("Please Select Atleast Record");
                return;
            }
            const data = {
                "locationId": this.state.locationId,
                "userIds":a.toString()
            }
    
            return Fetcher.post(this.props.app.apiPath + '/api/obd/bulk/user/location/addition', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
                .then(res => {
                    Notify.notify(res.message);
                    this.state.selectedRows= [];
                    this.state.locationId="";
                    setTimeout(() => {
                            window.location.reload(false);
                    }, 1000);
                })
                .catch(err => {
                    console.log('Error in Adding Users Location', err);
                });
    }
    

    render() {
        const allUsers = this.state.allUsersList;
        let columns='';

        const { selectedRows } = this.state;
        columns = [
            {
                dataField: 'select',
                text: 'Select',
                headerFormatter: () => (
                  <input
                    type="checkbox"
                    checked={this.isSelectedAll()}
                    onChange={(e) => this.handleSelectAll(e.target.checked)}
                  />
                ),
                formatter: (cell, row) => (
                  <input
                    type="checkbox"
                    checked={this.state.selectedRows.includes(row.userId)}
                    onChange={(e) => this.handleSelectRow(row, e.target.checked)}
                  />
                ),
                headerStyle: { width: '50px' },
              },
            {
                dataField: 'userId',
                text: 'User Id',
                sort: true,
                hidden: false
            },
            {
                dataField: 'username',
                text: 'User Name',
                sort: true,
                hidden: false
            },
        ];
        
        
        const products = Array.isArray(allUsers) ? allUsers.map((users, index) => {
                return {
                    userId: users.userId,
                    username : users.username
                };
        }) : []

        const lAList = cloneDeep(this.state.locationList);
              let locationDropdown = '';
              if (!isEmpty(lAList) && (!('error' in lAList))) {
                    locationDropdown = Array.isArray(lAList) ? lAList.map((obj, index) => {
                      return (
                             <option value={obj.locationId} key={`location${index}`} >{obj.locationName}</option>
                              );
                       }) : [];
               } else {
                   locationDropdown = <p>No Location found</p>;
               }

        const { SearchBar, ClearSearchButton } = Search;

        const defaultSorted = [{
            dataField: 'userId',
            order: 'desc'
        }];

        const { ExportCSVButton } = CSVExport;

        const paginationOption = {
            custom: true,
            totalSize: products.length,
            sizePerPage: this.state.sizePerPage,
            sizePerPageList: [10, 25, 50, 100],
            onSizePerPageChange: this.handleSizePerPageChange
        };

        return (
            <Card>
                <Card.Header>Bulk User Location Add</Card.Header>
                <Card.Body>
                    <div className="campaign-list-table-cont">
                        {<PaginationProvider pagination={paginationFactory(paginationOption)}>
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (<ToolkitProvider 
                                                    keyField="userId"
                                                    data={products}
                                                    columns={columns}
                                                    search bootstrap4>
                                    {props => (<div className="">
                                        Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
                                        <span className="search-cont"><SearchBar {...props.searchProps} /></span>
                                        <ClearSearchButton className="btn-primary" {...props.searchProps} />
                                        {/* <ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton> */}
                                        { (this.state.selectedRows.length >0 ) ? <Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.bulkAdd()}>Bulk Add</Button> : <></>}
                                        {/* { ((this.state.selectedRows.length ===0) && (this.props.app.role === 'ROLE_SADMIN') ) ? <a data-tip="Add File" key={`add-file`} className="float-right btn btn-default btn-success ml-2" onClick={(e) => { this.allAdd() }} href="#">Add All</a> : <></>} */}
                                        <hr/>

                                        <Form method="post" onSubmit={this.filterPrompt}>
                                            <Row>
                                                <Col sm ={4}>
                                                    <Form.Group className="form-group" >
                                                        <Form.Select as="select" required name="locationId" onChange={e => this.setvalue(e)}>
                                                            <option value="">Select Location</option>
                                                                    {locationDropdown}
                                                            </Form.Select>
                                                        </Form.Group>
                                                </Col>

                                            </Row>
                                        </Form>

                                        <BootstrapTable 
                                                      key={this.state.selectedRows.join(',')}
                                                      bordered={true} 
                                                      condensed defaultSorted={defaultSorted}
                                                      wrapperClasses="table-responsive"
                                                      classes="voicelist-table" {...props.baseProps}
                                                       {...paginationTableProps} 
                                                       noDataIndication={() => (<div>{'No data available'}</div>)} 
                                                        />
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>)}
                                </ToolkitProvider>)
                            }
                        </PaginationProvider>}
                    </div>
                     <Modal size="md" show={this.state.confirmBulkAdd} onHide={() => this.confirmBulkAdd(false, '')}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Add All User Location
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to Add All User Location?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.confirmBulkAdd(false, '')} variant="secondary">CANCEL</Button>
                            <Button onClick={this.addBulk} variant="primary">YES</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="md" show={this.state.confirmAddAll} onHide={() => this.confirmAddAll(false, '')}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Add All User Location
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to Add All User Location?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.confirmAddAll(false, '')} variant="secondary">CANCEL</Button>
                            <Button onClick={this.addAll} variant="primary">YES</Button>
                        </Modal.Footer>
                    </Modal>
                </Card.Body>
            </Card>
        );
    }
}


export default BulkLocationAddition;