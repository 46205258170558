import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class UpdateGroupAgent extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
            eventName : '',
			disableState : false,
			userAiAdded : false,
            groupName : '',
            agentName : '',
            agentNumber : '',
            agentRows : [],
            tableFlag : false,
			agentType : ''
		}

	}

	componentDidMount() {

        const apiPath = this.props.app.apiPath;
		const gId = this.props.selectedGroupId;
        Fetcher.get(apiPath + '/api/obd/group/agent/' + gId)
			.then(res => {
				this.setState({
                    groupName : res.groupName,
                    agentRows : res.agents,
                    tableFlag : true
				})
                console.log("Group name : "+res.groupName);
                console.log("Agents : "+this.state.agentRows);
                
			})
			.catch(err => { console.log('Error in fetching Group Agent data', err) });
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

    handleAddRow = () => {
		if (this.state.agentNumber !== '' && this.state.agentName !== '') {
			
			const item = {
				agentNumber: this.state.agentNumber,
				agentName: this.state.agentName,
				agentType : this.state.agentType
			};
			
			this.state.agentRows.push(item);
			this.setState({
				agentRows: this.state.agentRows
			});
		}
		else {
			Notify.notify("Please select Agent Number and Agent Name.");
		}

        console.log("agent rows : "+this.state.agentRows);
        if(this.state.agentRows.length>0){
            this.state.tableFlag = true;
        }
        this.state.agentNumber="";
        this.state.agentName="";
	};

    handleRemoveRow = (index) => {

		let arr1 = this.state.agentRows;
		let arr2= this.state.agentRows.splice(index,1);
		let arr3 = [];
		 for(let i=0;i<arr1.length;i++){
			if((arr1[0].agentNumber === arr2[0].agentNumber) && (arr1[0].agentName === arr2[0].agentName) && (arr1[0].agentType === arr2[0].agentType)){
				continue;
			}else{
                arr3.push(arr1[i]);
			}
		 }
		 this.setState({
		  agentRows: arr3
		 });
	};

	handleUpdateGroup = (event) => {
		event.preventDefault();

        if(this.state.agentRows==0){
            Notify.notify("Please Select Agent Number and Agent Name");
            return;
        }

		const data = {
            "userId" : Number(this.state.userId),
			"groupName" : this.state.groupName,
            "agents" : this.state.agentRows,
            "groupId" : this.props.selectedGroupId
		};

		console.log(JSON.stringify(data));

		return Fetcher.post(this.props.app.apiPath + '/api/obd/edit/group/agent', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
					
				let userAiAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					userAiAdded = true;
				}
				this.setState({
					userAiAdded: userAiAdded
				})
			})
			.catch(err => {
				console.log('Error in Updating Agent Group', err);
				Notify.notify('Error in Updating Agent Group');
			});
	}

	checkAgentType = (aType) =>{

		if(aType==="0"){
            return  "Normal Agent";
		}else if(aType === "1"){
            return "Call Center Agent";
		}else{
			return "";
		}

	}

	render() {
		if (this.state.userAiAdded === true) {
			return <Redirect to='/group-agent-list' />
		}

        const agentRows = cloneDeep(this.state.agentRows);
		const agentsTable = agentRows.map((item, index) => {
			return <tr key={index}>
				<td>{this.state.agentRows[index].agentNumber}</td>
				<td>{this.state.agentRows[index].agentName}</td>
				<td>{this.checkAgentType(this.state.agentRows[index].agentType)}</td>
				<td>
					<i onClick={() => this.handleRemoveRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		});
		

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleUpdateGroup(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update Group Agent</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={2}>
										<Form.Label>Group Name</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control readOnly required name="groupName" type="text" placeholder="Group Name" onChange={e => this.setvalue( e )} value={this.state.groupName} />
										</Form.Group>
									</Col>
									<Col sm={2}>
										<Form.Label>Agent Name</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control name="agentName" type="text" placeholder="Agent Name" onChange={e => this.setvalue( e )} value={this.state.agentName} />
										</Form.Group>
									</Col>
									<Col sm={2}>
										<Form.Label>Agent Number</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control name="agentNumber" type="text" placeholder="Agent Number" onChange={e => this.setvalue( e )} value={this.state.agentNumber} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Label>Agent Type</Form.Label>
											<Form.Group className="form-group required control-label">
												<Form.Select name="agentType" onChange={e => this.setvalue(e)} as="select">
													<option value={this.state.agentType}>Select Agent Type</option>
													<option value="0">Normal Agent</option>
													<option value="1">Call Center Agent</option>
												</Form.Select>
											</Form.Group>
									</Col>
                                    <Col sm={1} className="plusIcon">
										<i onClick={this.handleAddRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>

                                    {(this.state.tableFlag) && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Agent Number</th>
														<th>Agent Name</th>
														<th>Agent Type</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{agentsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}
				
				
								</Row>
								<Button variant="primary" type="submit">Update</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default UpdateGroupAgent;