import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";

// import "./index.css";
class UpdateUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			planId: '',
			planName: '',
			ttsPlanName: '',
			accountType: '',
			account: '',
			type: '',
			userType: '',
			channels: '',
			redirectToUserManagement: false,
			planList: [],
			sendDate: '',
			expiryDate: '',
			moduleList: '',
			moduleRows: [],
			allocatedmoduleList: [],
			moduleId: '',
			allocatedGroupList: [],
			groupList: [],
			locationList: [],
			groupRows: [],
			groupId: '',
			locationId: '',
			groupName: '',
			locationName: '',
			groupsList: '',
			locationsList: '',
			locationRows: [],
			margin: '',
			interval: '',
			threshold: '',
			tier : '',
			isSub : false,
			cutting : false,
			userSubConfig : '',
			userSubConfigObj : {},
			isSubValue:false,
			settings: {},
			Setting1: false,
			Setting2: false,
			Setting3: false,
			Setting4: false,
			margin1: '',
			interval1: '',
			threshold1: '',
			starting_range1: '',
			ending_range1: '',
			margin2: '',
			interval2: '',
			threshold2: '',
			starting_range2: '',
			ending_range2: '',
			margin3: '',
			interval3: '',
			threshold3: '',
			starting_range3: '',
			ending_range3: '',
			margin4: '',
			interval4: '',
			threshold4: '',
			starting_range4: '',
			ending_range4: '',
			pulseDuration: 0,
			inboundFlag : true,
			ttsCredits : '',
			ttsCreditsFlag : false,
			showSubmission: false,
			ttsPlanList : '',
			ttsPlanId : ''
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.selectedUserId;
		const parent = this.props.app.userId;
		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + parent)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
		
		Fetcher.get(apiPath + '/api/obd/tts/plans/list/' + parent)
			.then(res => {
				this.setState({
					ttsPlanList: res
				})
			})
			.catch(err => { console.log('Error in fetching TTS Plans', err) });
		
		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
		
		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });
			
		// apiPath
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					emailid: res.emailid,
					number: res.number,
					address: res.address,
					company: res.company,
					pincode: res.pincode,
					planId: res.planId,
					planName: res.planName,
					ttsPlanName: res.ttsPlanName,
					userType: res.userType,
					type: res.userType==='user' ? 'User' : 'Reseller',
					accountType: res.accountType,
					account: res.accountType===0 ? 'Promotional': 'Transactional',
					channels: res.channels,
					expiryDate: res.expiryDate,
					groupRows: res.group,
					locationRows: res.location,
					cutting : res.cutting,
					userSubConfig : res.userSubConfig,
					isSub : res.sub,
					settings:res.settings,
					pulseDuration: res.pulseDuration,
					ttsCredits : res.ttsCredits,
					showSubmission: true,
					ttsPlanId : res.ttsPlanId
				})
				console.log("Setting : "+this.state.setting);
				if(this.state.isSub === 1){
					this.state.isSubValue = true;
				}
				for (let i = 1; i <= 4; i++) {
					const tier = this.state.settings[`tier${i}`];
					if (tier) {
						this.setState({
							[`Setting${i}`]: true,
							[`margin${i}`]: tier.margin,
							[`interval${i}`]: tier.interval || '',
							[`threshold${i}`]: tier.threshold,
							[`starting_range${i}`]: tier.startRange || '',
							[`ending_range${i}`]: tier.endRange || ''
						});
					}
				}	
				this.state.userSubConfigObj = this.state.userSubConfig;
				if(this.state.cutting){
					this.state.margin = this.state.userSubConfigObj.margin;
					this.state.interval = this.state.userSubConfigObj.interval;
					this.state.tier = this.state.userSubConfigObj.tier;
					this.state.threshold = this.state.userSubConfigObj.threshold;
					//this.state.isSub = this.state.userSubConfigObj.isSub;
					// if(this.state.userSubConfigObj.isSub === "1"){
					// 	this.state.isSubValue = true;
					// }
				}
				console.log("User Config : "+this.state.userSubConfigObj);
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		// Get User Module List
		Fetcher.get(apiPath + '/api/obd/module/list/' + userId)
		.then(res => {

			for(let i=0;i<res.length;i++){
				if(res[i].moduleId == '7'){
					this.setState({
					    ttsCreditsFlag : true	
					})
				}
			}
			this.setState({
				moduleRows: res
			})
		})
		.catch(err => { console.log('Error in fetching Modules', err) });

		// Get Module List
		Fetcher.get(apiPath + '/api/obd/module')
		.then(res => {
			this.setState({
				allocatedmoduleList: res
			})
		})
		.catch(err => { console.log('Error in fetching Modules', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	sendLaterDateChange = date => {
		this.setState({
			sendDate: date,
			expiryDate: date
		});
	};
	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};

			const checkGroup = obj => obj.groupName === groupName;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			//const checkGroup = obj => obj.groupId === groupId;
			/*if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}*/
			
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};
	handleAddLocationRow = () => {
		if (this.state.locationId !== '') {
			let locationName = this.state.locationId.substring(this.state.locationId.lastIndexOf(',')+1,this.state.locationId.length);
			let locationId = this.state.locationId.substring(0,this.state.locationId.lastIndexOf(','));
			const item = {
				locationId: locationId,
				locationName: locationName
			};
			const checkLocation = obj => obj.locationName === locationName;
			if(this.state.locationRows.some(checkLocation))
			{
					Notify.notify('This Location is already selected');
					return;
			}
			this.state.locationRows.push(item);
			this.setState({
				locationRows: this.state.locationRows
			});
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		else {
			Notify.notify("Please select Location");
		}
	};
	handleRemoveGroupRow = (index) => {
		const newGroupRows = [...this.state.groupRows];
		newGroupRows.splice(index, 1);
		this.setState({
			groupRows: newGroupRows
		});
		this.state.groupsList = {
			"groupsList": newGroupRows
		}
	};
	handleRemoveLocationRow = (index) => {
		const newLocationRows = [...this.state.locationRows];
		newLocationRows.splice(index, 1);
		this.setState({
			locationRows: newLocationRows
		});
		this.state.locationsList = {
			"locationsList": newLocationRows
		}
	};
	handleChangeGroupRow = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};
	handleChangeLocationRow = idx => e => {
		const { name, value } = e.target;
		const locationRows = [...this.state.locationRows];
		locationRows[idx] = {
			[name]: value
		};
		this.setState({
			locationRows
		});
	};
	handleAddModuleRow = () => {
		if (this.state.moduleId !== '') {
        
			let moduleName = this.state.moduleId.substring(this.state.moduleId.lastIndexOf(',')+1,this.state.moduleId.length);
			let moduleId = this.state.moduleId.substring(0,this.state.moduleId.lastIndexOf(','));
			if(moduleId== '7'){
			  this.setState({
				ttsCreditsFlag : true
			  })
		   }
			const item = {
				moduleId: moduleId,
				moduleName: moduleName
			};
			if(!this.state.moduleRows.length)
			{
				this.state.moduleRows = [];
			}
			const checkModule = obj => obj.moduleName === moduleName;
			if(this.state.moduleRows.some(checkModule))
			{
					Notify.notify('This module is already selected');
					return;
			}
			//const checkGroup = obj => obj.groupId === groupId;
			/*if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}*/
			
			this.state.moduleRows.push(item);
			this.setState({
				moduleRows: this.state.moduleRows
			});
			this.state.moduleList = {
				"moduleList": this.state.moduleRows
			}
		}
		else {
			Notify.notify("Please select Module");
		}
	};
	handleRemoveModuleRow = (index) => {
        

		let arr1 = this.state.moduleRows;
		let arr2= this.state.moduleRows.splice(index,1);
		console.log(">>>>> "+arr2);
		if(arr2[0].moduleId == 7){
			this.setState({
				ttsCreditsFlag : false,
				ttsCredits : ''
			})
		}
		let arr3 = [];
		 for(let i=0;i<arr1.length;i++){
			if(arr1[0].moduleId === arr2[0].moduleId){
				continue;
			}else{
                arr3.push(arr1[i]);
			}
		 }
		this.setState({
			moduleRows:  arr3
		});
		this.state.moduleList = {
			"moduleList": arr3
		}
	};
	handleChangeModuleRow = idx => e => {
		const { name, value } = e.target;
		const moduleRows = [...this.state.moduleRows];
		moduleRows[idx] = {
			[name]: value
		};
		this.setState({
			moduleRows
		});
	};

	updateUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		let date = '';
		let sendDateTime='';
		if(state.groupRows.length == 0)
		{
			Notify.notify('Please select atleast one group');
			return;
		}
		if(state.locationRows.length == 0)
		{
			Notify.notify('Please select atleast one location');
			return;
		}
		if(state.locationsList === '')
		{
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		if(state.groupsList === '')
		{
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		if(state.moduleRows.length == 0)
		{
			Notify.notify('Please select atleast one module');
			return;
		}
		if(state.moduleRows.length!==0){
			let mId = "";
			for(let i =0;i<state.moduleRows.length;i++){
                
				mId = mId+","+state.moduleRows[i]['moduleId'];

			}
			if(mId.includes('6')){
				if(!(mId.includes('5'))){
					Notify.notify('Please also select Dedicated Cli Module.');
			        return;
				}
			}
		}
		if(state.moduleList === '')
		{
			this.state.moduleList = {
				"moduleList": this.state.moduleRows
			}
		}
		if (state.sendDate == null) {
			Notify.notify('Please Select Expiry Date');
			return;
		}
		if(state.sendDate === '')
		{
			sendDateTime = state.expiryDate;
		}
		else
		{
			date = state.sendDate;
			
			sendDateTime = this.addZero(date.getFullYear());
			sendDateTime += "-";
			sendDateTime += this.addZero((date.getMonth() + 1));
			sendDateTime += "-"
			sendDateTime += this.addZero(date.getDate());
			sendDateTime += " 23:59:59"
			/*sendDateTime += this.addZero(date.getHours());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getMinutes());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getSeconds());*/
		}

		let sValue = 0;
		if(this.state.isSubValue){
			sValue = 1;
		}

		const updatedsettings = {};

		for (let i = 1; i <= 4; i++) {
			// if (i === 1 ){
            // 	const planList = this.state.planList;
		    // 	const matchingPlan = planList.find(plan => plan.planId == this.state.planId);
			// 	if (matchingPlan && matchingPlan.pulseDuration != 1){
			// 		this.setState({ Cutting1: false });
			// 		continue;
			// 	}
			// }
			const setting = state[`Setting${i}`];
			if (setting) {
				updatedsettings[`tier${i}`] = {
					margin: state[`margin${i}`],
					interval: state[`interval${i}`] || "",
					threshold: state[`threshold${i}`],
					startRange: state[`interval${i}`] ? "" : state[`starting_range${i}`],
					endRange: state[`interval${i}`] ? "" : state[`ending_range${i}`]
				};
				if (parseInt(state[`starting_range${i}`]) >= parseInt(state[`ending_range${i}`])) {
					alert(`For Setting${i}, starting range cannot be greater than or equal to ending range`);
					return;
				}
				// if (i === 1 && state.pulseDuration > 1) {
				// 	alert('User Pulse Duration is Greater than 1 while applying cutting for Cutting1');
				// 	return;
				// }
				
			}
		}

		if((Object.keys(updatedsettings).length !== 0)){
            
		const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime,
			"moduleRows": JSON.stringify(this.state.moduleList),
			"groupRows": JSON.stringify(this.state.groupsList),
			"locationRows": JSON.stringify(this.state.locationsList),
			settings :updatedsettings,
			isSetting : true,
		    ttsCredits : (this.state.ttsCredits == '' ? '0' : this.state.ttsCredits),
			ttsPlanId : this.state.ttsPlanId,
			sub : sValue
			
		}
		this.saveData(data, false);
	}
	 else{
			const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime,
			"moduleRows": JSON.stringify(this.state.moduleList),
			"groupRows": JSON.stringify(this.state.groupsList),
			"locationRows": JSON.stringify(this.state.locationsList),
			isSetting : false,
			ttsCredits : (this.state.ttsCredits == '' ? '0' : this.state.ttsCredits),
			ttsPlanId : this.state.ttsPlanId,
			sub : sValue

			
		}
		this.saveData(data, false);
		}

	}
	saveData = (data) => {
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToUserManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'User Updation Failed') {
					redirectToUserManagement = false;
				} else {
					redirectToUserManagement = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToUserManagement: redirectToUserManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Account', err)
				Notify.notify('Error in Updating Account');
			});

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	toggleCheckedChange = (e, name) => {
		this.setState({
			[name]: e.target.checked,
		});
	};

	renderSettingOption = (optionName) => {
		const isAdmin = this.props.app.role === 'ROLE_SADMIN';
		const optionState = this.state[optionName];
		const marginValue = this.state[`margin${optionName.slice(-1)}`];
		const thresholdValue = this.state[`threshold${optionName.slice(-1)}`];
		const isTier2or3 = optionName === 'Setting2' || optionName === 'Setting3';
		let intervalValue;
        if (!isTier2or3) {
          intervalValue = this.state[`interval${optionName.slice(-1)}`];
        }
	
		return (
			<div>
				<Row>
					{isAdmin && (
						<Col sm={3}>
							<Form.Group className="form-group">
								<Form.Check
									checked={optionState}
									onChange={(e) => this.toggleCheckedChange(e, optionName)}
									custom
									inline
									name={optionName}
									label={`${optionName}`}
									id={optionName}
									type="checkbox"
								/>
							</Form.Group>
						</Col>
					)}
				</Row>
				<Row>
					{optionState && isAdmin && (
						<>
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Select
										className="dd"
										required
										name={`margin${optionName.slice(-1)}`}
										value={marginValue}
										onChange={(e) => this.setvalue(e)}
										as="select"
									>
										<option value="">Select Margin</option>
										{[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((percent, index) => (
											<option key={index} value={index + 1}>{percent}%</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							{isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`starting_range${optionName.slice(-1)}`}
                                            value={this.state[`starting_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"        
                                        >        
                                            <option value="">Starting Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                                <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}        
                                        </Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`ending_range${optionName.slice(-1)}`}
                                            value={this.state[`ending_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"
                                        >
                                            <option value="">Ending Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                              <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}
                                        </Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							{!isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select
												className="dd"
												required
												name={`interval${optionName.slice(-1)}`}
												value={intervalValue}
												onChange={(e) => this.setvalue(e)}
												as="select"
											>
												<option value="">Select Interval</option>
												{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
													<option key={seconds} value={seconds}>{seconds} sec</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Control
										required
										name={`threshold${optionName.slice(-1)}`}
										type="number"
										placeholder="Threshold"
										onChange={(e) => this.setvalue(e)}
										value={thresholdValue}
									/>
								</Form.Group>
							</Col>
						</>
					)}
				</Row>
			</div>
		);
	};

	render() {
		if (this.state.redirectToUserManagement === true) {
			return <Redirect to={'/user-list/'} />
		}
		const groupRows = cloneDeep(this.state.groupRows);
		const allocatedGroupsTable = Array.isArray(groupRows) ? groupRows.map((item, index) => {
			return <tr key={index}>
				<td>{groupRows[index].groupName}</td>
				<td>
					<i onClick={() => this.handleRemoveGroupRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const locationRows = cloneDeep(this.state.locationRows);
		const locationsTable = Array.isArray(locationRows) ? locationRows.map((item, index) => {
			return <tr key={index}>
				<td>{locationRows[index].locationName}</td>
				<td>
					<i onClick={() => this.handleRemoveLocationRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const groupList = cloneDeep(this.state.groupList);
		let groupDropdown = '';
		if (!isEmpty(groupList) && (!('error' in groupList))) {
			groupDropdown = Array.isArray(groupList) ? groupList.map((obj, index) => {
				return <option value={obj.groupId+','+obj.groupName} key={`group${index}`} >{obj.groupName}</option>
			}) : []

		} else {
			groupDropdown = <option value="" >No group found</option>
		}

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return <option value={obj.locationId+','+obj.locationName} key={`location${index}`} >{obj.locationName}</option>
			}) : []

		} else {
			locationDropdown = <option value="" >No location found</option>
		}
		const allocatedmoduleList = cloneDeep(this.state.allocatedmoduleList);
		let moduleDropdown = '';
		if (!isEmpty(allocatedmoduleList) && (!('error' in allocatedmoduleList))) {
			moduleDropdown = Array.isArray(allocatedmoduleList) ? allocatedmoduleList.map((obj, index) => {
				return <option value={obj.moduleId+','+obj.moduleName} key={`location${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			moduleDropdown = <option value="" >No module found</option>
		}
		const moduleRows = cloneDeep(this.state.moduleRows);
		const allocatedModulesTable = Array.isArray(moduleRows) ? moduleRows.map((item, index) => {
			return <tr key={index}>
				<td>{moduleRows[index].moduleName}</td>
				<td>
					<i onClick={() => this.handleRemoveModuleRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];
		let sendLaterDatepicker='';
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
		sendLaterDatepicker =
				<Col sm={4}>
					<Form.Group className="form-group required">
						<Form.Label>User Expiry</Form.Label>
							<DatePicker
								className="form-control"
								selected={this.state.expiryDate ? new Date(this.state.expiryDate) : this.state.sendDate}
								timeCaption="Time"
								minDate={new Date()}
								dateFormat="yyyy-MM-dd"
								onChange={this.sendLaterDateChange}
								filterTime={filterTime}
							/>
					</Form.Group>
				</Col>


		const planList = cloneDeep(this.state.planList);
		let matchingPlan;
		if(planList && planList.length > 0){
			matchingPlan = planList.find(plan => plan.planId == this.state.planId);
		} 
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option>No Plan</option>
		}


		const ttsPlanList = cloneDeep(this.state.ttsPlanList);
		let matchingTTSPlan;
		if(ttsPlanList && ttsPlanList.length > 0){
			matchingTTSPlan = ttsPlanList.find(plan => plan.planId == this.state.ttsPlanId);
		} 
		let ttsPlanDropdown = '';
		if (!isEmpty(ttsPlanList) && (!('error' in ttsPlanList))) {
			ttsPlanDropdown = Array.isArray(ttsPlanList) ? ttsPlanList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			ttsPlanDropdown = <option>No Plan</option>
		}
			
		return (

			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Label>Name</Form.Label>
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Label>Email ID</Form.Label>
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Label>Mobile Number</Form.Label>
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Label>Address</Form.Label>
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue( e )} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Label>Pincode</Form.Label>
											<Form.Control required name="pincode" type="text" placeholder="Pincode" onChange={e => this.setvalue( e )} value={this.state.pincode} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Label>Company</Form.Label>
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group" >
											<Form.Label>Update Plan</Form.Label>
											<Form.Select required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.planId}>{this.state.planName}</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group" >
										<Form.Label>Update User Type</Form.Label>
											<Form.Select required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.userType}>{this.state.type}</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group" >
											<Form.Label>Update Account Type</Form.Label>
											<Form.Select required name="accountType" onChange={e => this.setvalue(e)} as="select">
												<option value="{this.state.accountType}">{this.state.account}</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{sendLaterDatepicker}
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group">
											<Form.Select name="moduleId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Module</option>
												{moduleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddModuleRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
									<Col sm={6}>
										<div className="react-bootstrap-table">
											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Module</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedModulesTable}
												</tbody>
											</table>
										</div>
									</Col>
									<Col sm={1}>	
									</Col>
								</Row>

                               {(this.state.ttsCreditsFlag) && <Row>
                                    {/* <Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Label>TTS Credits</Form.Label>
											<Form.Control required name="ttsCredits" type="number" placeholder="Add TTS Credits" onChange={e => this.setvalue(e)} value={this.state.ttsCredits} />
										</Form.Group>
									</Col> */}
									<Col sm={4}>
										<Form.Group className="form-group" >
											<Form.Label>Update TTS Plan</Form.Label>
											<Form.Select required name="ttsPlanId" onChange={e => this.setvalue(e)} as="select">
												{this.state.ttsPlanName!='' ? <option value={this.state.ttsPlanId}>{this.state.ttsPlanName}</option> :
												<option value="">Select TTS Plan</option> }
												{ttsPlanDropdown}
											</Form.Select>
										</Form.Group>
									</Col>

								</Row>}

								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={6}>
										<Form.Group className="form-group">
											<Form.Select name="groupId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Group</option>
												{groupDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddGroupRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
									<Col sm={6}>
										<Form.Group className="form-group">
											<Form.Select name="locationId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Location</option>
												{locationDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddLocationRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
								</Row>}
								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedGroupsTable}
												</tbody>
											</table>
										</div>
									</Col>
									<Col sm={1}>
										
									</Col>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Location</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{locationsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}

                               <Row>
									{(this.props.app.role === 'ROLE_SADMIN' || (this.state.showSubmission) ) && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.isSubValue} onChange={( e ) => this.toggleChange( e, 'isSubValue' )} custom inline name="isSubValue" label="Submission" id="isSubValue" type="checkbox" />
										     {console.log(this.state.isSubValue)}
										</Form.Group>
										</Col>}
									{/* {(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.cutting} onChange={( e ) => this.toggleChange( e, 'cutting' )} custom inline name="cutting" label="Cutting" id="cutting" type="checkbox" />
										</Form.Group>
									</Col>} */}
								</Row>

								<Button className="btn-round" variant="primary" type="submit">Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateUser;