import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';

import "./index.css";
class ListTTS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customerId: '',
			sizePerPage: 10,
            ttslist: [],
            selectedTTSId : 0
		}
	}
	componentDidMount() {
		this.getTTS();
		const savedSizePerPage = Cookies.get('voiceFileListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
		console.log(">>>>>>>>>>> "+this.props.app.role);
	}

	getTTS = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		
			Fetcher.get(apiPath + '/api/obd/tts/list/' + userId)
			.then(res => {
				this.setState({
					ttslist: res,
					confirmDelete: false
				})
			})
			.catch(err => { console.log('Error in fetching TTS', err) });

	}


	downloadVoiceFile = (promptName, customerId) => {
		window.open(this.props.app.apiPath + '/assets/voice/' + customerId + '/prompts/' + promptName, 'Download');
	}

	confirmDelete = (status, ttsId) => {
		this.setState({
			confirmDelete: status,
			selectedTTSId: ttsId
		});
	}


	

	deletePrompt = () => {
		const ttsID = this.state.selectedTTSId;
		const userId = this.props.app.userId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/tts/' + ttsID+"/"+userId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getTTS();
			})
			.catch(err => {
				console.log('Error in Deleting TTS', err);
			});
	}


	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('voiceFileListsizePerPage', newSizePerPage);
	}

	render() {

		const tts = this.state.ttslist;
        let vis = true;
		if(this.props.app.role=='ROLE_SADMIN'){
              vis = false;
		}

		var columns='';
		
		{
			columns = [
			{
				dataField: 'id',
				text: 'TTS Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'username',
				text: 'Username',
				sort: true,
				hidden: vis
			},
			{
				dataField: 'language',
				text: 'Language',
				sort: true,
				searchable: true
			}, {
				dataField: 'gender',
				text: 'Gender',
				sort: false,
				searchable: false
			}, {
				dataField: 'category',
				text: 'Category',
				sort: false,
				searchable: false
			},
			{
				dataField: 'filename',
				text: 'File Name',
				sort: true,
				searchable: true
			},
			{
				dataField: 'textScript',
				text: 'Text Script',
				sort: false,
				searchable: false
			},
            {
				dataField: 'characterCount',
				text: 'Total Length',
				sort: false,
				searchable: false
			},
            {
				dataField: 'reqDate',
				text: 'Request Date',
				sort: false,
				searchable: false
			},
            {
				dataField: 'status',
				text: 'Status',
				sort: false,
				searchable: false
			},
			{
				dataField: 'deletedBy',
				text: 'Deleted By',
				sort: false,
				searchable: false,
				hidden: (this.props.app.role=='ROLE_SADMIN' ? false :true)
			},
			{
				dataField: 'deleted_date',
				text: 'Deleted Date',
				sort: false,
				searchable: false,
				hidden: (this.props.app.role=='ROLE_SADMIN' ? false :true)
			},
			{
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
        }
        console.log("Length : "+tts.length);
		const products = Array.isArray(tts) ? tts.map((tts, index) => {
			var status='';
			switch ( tts.status ) {
				case 0:
					status = 'Pending';
					break;
				case 1:
					status = 'Approved';
					break;
				case 2:
					status = 'Rejected';
					break;
				case 9:
					status = 'Deleted';
					break;
				default:
					status = '';
					break;
			}
			
			let action = [];
			
			
                action = [<a key={`delete-tts`} className="btn-sm btn btn-default btn-success ml-2" onClick={(e) => { this.confirmDelete(true, tts.id) }} href="#">Delete</a>];
					// action.push(<a key={`${index}-tts-edit`} className="btn-sm btn btn-default btn-danger ml-2" href={`${this.props.app.urlPrefix}/edit-tts/${tts.id}`} >Edit</a>);
					
				
                return {
					id: tts.id,
					username : tts.userName,
					language: (tts.language == 'hi-IN' ? 'Hindi' : 'English' ),
                    gender : (tts.gender),
                    category : tts.category,
                    filename : tts.filename,
                    textScript : tts.textScript,
                    characterCount : tts.characterCount,
                    reqDate : tts.reqDate,
                    status : status,
					action: action,
					deletedBy: tts.deletedBy,
					delete_date : tts.delete_date,
					remarks : tts.remarks
				};
			
			
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'promptId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		return (
			<Card>
				<Card.Header>TTS</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider keyField="id" data={products} columns={columns} search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<hr/>
										<BootstrapTable bordered={true} condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="voicelist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete TTS
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this TTS
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deletePrompt} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					
					
				</Card.Body>
			</Card>
		);
	}
}


export default ListTTS;