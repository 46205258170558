import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal, Card ,  Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';
import { cloneDeep, isEmpty } from 'lodash';

import "./index.css";
class BulkGroupUpdation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            sizePerPage: 10,
            selectedRows: [],
            confirmBulkDelete: false,
            confirmDeleteAll: false,
            groupList : [],
            groupId1 : "",
            userGroupList : []
        }
    }
    componentDidMount() {
        this.getGroup();
    }

    getGroup = () => {
                const apiPath = this.props.app.apiPath;
        
                Fetcher.get(apiPath + '/api/obd/groups')
                    .then(res => {
                        this.setState({
                            groupList: res
                        })
                    })
                    .catch(err => { console.log('Error in fetching Group List', err) });
    }

    setvalue = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }


    confirmBulkDelete = (status) => {
        this.setState({
            confirmBulkDelete: status,
            confirmApprove: false,
            confirmReject: false,
            showListenModal: false
        });
    }

    confirmDeleteAll = (status) => {
        this.setState({
            confirmDeleteAll: status,
            confirmApprove: false,
            confirmReject: false,
            showListenModal: false
        });
    }


    filterPrompt = (event) => {
        
        event.preventDefault();
        if(this.state.groupId1 === ''){
            Notify.notify("Please Select Group");
            return 
        }

        const data = {
                "groupId" : this.state.groupId1
        }

        //this.state.voicelist = [];
        return Fetcher.post(this.props.app.apiPath + '/api/obd/user/groups/list', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
                        .then(res => {
                                this.setState({
                                    userGroupList : res,
                                })
                        })
                        .catch(err => {
                            console.log('Error in Fetching User Group', err);
                            Notify.notify('Error in Fetching User Group');
        });
    }

    handleSizePerPageChange = (newSizePerPage) => {
        this.setState({ sizePerPage: newSizePerPage });
        Cookies.set('voiceFileListsizePerPage', newSizePerPage);
    }

    handleSelectAll = (isSelected, rows) => {
        if (isSelected) {
            this.setState({
                selectedRows: rows.map(row => row.promptId)
            });
        } else {
            this.setState({
                selectedRows: []
            });
        }
    };

    handleSelectRow = (row, isSelect) => {
        this.setState(prevState => {
               const selectedRows = [...prevState.selectedRows];
               if (isSelect) {
                            selectedRows.push(row.userId);
               } else {
                        const index = selectedRows.indexOf(row.userId);
                        if (index > -1) {
                                    selectedRows.splice(index, 1);
                        }
                }
        return { selectedRows };
       });

    };



    bulkDelete = () => {
        console.log("selectd : "+this.state.selectedRows);
        this.confirmBulkDelete(true);

    }

    allDelete = () => {
        this.confirmDeleteAll(true);
    }

    deleteAll = () => {
    
            let a = this.state.userGroupList.map(p => p.userId);
    
            console.log("------------- : "+a);
    
            if(a.toString().length===0){
                Notify.notify("Please Select Atleast Record");
                return;
            }
            const data = {
                "groupId": this.state.groupId1,
                "userIds":a.toString()
            }
    
            return Fetcher.post(this.props.app.apiPath + '/api/obd/bulk/user/group/deletion', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
                .then(res => {
                    Notify.notify(res.message);
                    this.state.selectedRows= [];
                    this.state.groupId="";
                    setTimeout(() => {
                            window.location.reload(false);
                    }, 1000);
                })
                .catch(err => {
                    console.log('Error in Deleting Users Group', err);
                });
    }
    handleSelectAll = (isSelected) => {
        const allUserIds = this.state.userGroupList.map((group) => group.userId);
        this.setState({ selectedRows: isSelected ? allUserIds : [] });
      };
      
      isSelectedAll = () => {
        const { selectedRows, userGroupList } = this.state;
        return userGroupList.length > 0 && selectedRows.length === userGroupList.length;
      };

    deleteBulk = () => {
    
            let a = this.state.selectedRows.toString();
    
            console.log("------------- : "+a);
    
            if(a.toString().length===0){
                Notify.notify("Please Select Atleast Record");
                return;
            }
            const data = {
                "groupId": this.state.groupId1,
                "userIds":a.toString()
            }
    
            return Fetcher.post(this.props.app.apiPath + '/api/obd/bulk/user/group/deletion', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
                .then(res => {
                    Notify.notify(res.message);
                    this.state.selectedRows= [];
                    this.state.groupId1="";
                    setTimeout(() => {
                            window.location.reload(false);
                    }, 1000);
                })
                .catch(err => {
                    console.log('Error in Deleting Users Group', err);
                });
    }
    

    render() {
        const userGroups = this.state.userGroupList;
        let columns='';

        const { selectedRows } = this.state;
        columns = [
{
    dataField: 'select',
    text: 'Select',
    headerFormatter: () => (
      <input
        type="checkbox"
        checked={this.isSelectedAll()}
        onChange={(e) => this.handleSelectAll(e.target.checked)}
      />
    ),
    formatter: (cell, row) => (
      <input
        type="checkbox"
        checked={this.state.selectedRows.includes(row.userId)}
        onChange={(e) => this.handleSelectRow(row, e.target.checked)}
      />
      ),
               headerStyle: { width: '50px' },
            },
            {
               dataField: 'userId',
               text: 'User Id',
               sort: true,
            },
            {
               dataField: 'username',
               text: 'User Name',
               sort: true,
            },
            {
               dataField: 'groupname',
               text: 'Group Name',
               sort: true,
               searchable: true,
            },
            {
                dataField: 'userId',
                text: 'User Id',
                sort: true,
                hidden: false
            },
            {
                dataField: 'username',
                text: 'User Name',
                sort: true,
                hidden: false
            },
            {
                dataField: 'groupname',
                text: 'Group Name',
                sort: true,
                searchable: true
            },
             ];
        
        
        const products = Array.isArray(userGroups) ? userGroups.map((groups, index) => {
                return {
                    userId: groups.userId,
                    username : groups.username,
                    groupname : groups.groupName
                };
        }) : []

       const gAList = cloneDeep(this.state.groupList);
       let groupDropdown = '';
       if (!isEmpty(gAList) && (!('error' in gAList))) {
             groupDropdown = Array.isArray(gAList) ? gAList.map((obj, index) => {
               return (
                      <option value={obj.groupId} key={`group${index}`} >{obj.groupName}</option>
                       );
                }) : [];
        } else {
            groupDropdown = <p>No Group found</p>;
        }

        const { SearchBar, ClearSearchButton } = Search;

        const defaultSorted = [{
            dataField: 'userId',
            order: 'desc'
        }];

        const { ExportCSVButton } = CSVExport;

        const paginationOption = {
            custom: true,
            totalSize: products.length,
            sizePerPage: this.state.sizePerPage,
            sizePerPageList: [10, 25, 50, 100],
            onSizePerPageChange: this.handleSizePerPageChange
        };

        return (
            <Card>
                <Card.Header>Bulk User Group Deletion</Card.Header>
                <Card.Body>
                    <div className="campaign-list-table-cont">
                        {<PaginationProvider pagination={paginationFactory(paginationOption)}>
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (<ToolkitProvider 
                                                    keyField="userId"
                                                    data={products}
                                                    columns={columns}
                                                    search bootstrap4>
                                    {props => (<div className="">
                                        Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
                                        <span className="search-cont"><SearchBar {...props.searchProps} /></span>
                                        <ClearSearchButton className="btn-primary" {...props.searchProps} />
                                        {/* <ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton> */}
                                        { (this.state.selectedRows.length >0 ) ? <Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.bulkDelete()}>Bulk Delete</Button> : <></>}
                                        {/* { ((this.state.selectedRows.length ===0) && (this.props.app.role === 'ROLE_SADMIN') ) ? <a data-tip="Delete File" key={`delete-file`} className="float-right btn btn-default btn-success ml-2" onClick={(e) => { this.allDelete() }} href="#">Delete All</a> : <></>} */}
                                        <hr/>

                                        <Form method="post" onSubmit={this.filterPrompt}>
                                            <Row>
                                                <Col sm ={4}>
                                                    <Form.Group className="form-group" >
                                                        <Form.Select as="select" required name="groupId1" onChange={e => this.setvalue(e)}>
                                                            <option value="">Select Group</option>
                                                                    {groupDropdown}
                                                            </Form.Select>
                                                        </Form.Group>
                                                </Col>
                                                  
                                                <Col sm={4}>
                                                   <Button className="btn-round" variant="primary" type="submit" disabled={this.state.loading}>Filter</Button>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <BootstrapTable 
                                                      key={this.state.selectedRows.join(',')}
                                                      bordered={true} 
                                                      condensed defaultSorted={defaultSorted}
                                                      wrapperClasses="table-responsive"
                                                      classes="voicelist-table" {...props.baseProps}
                                                       {...paginationTableProps} 
                                                       noDataIndication={() => (<div>{'No data available'}</div>)} 
                                                        />
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>)}
                                </ToolkitProvider>)
                            }
                        </PaginationProvider>}
                    </div>
                     <Modal size="md" show={this.state.confirmBulkDelete} onHide={() => this.confirmBulkDelete(false, '')}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Delete User Group
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete All User Group?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.confirmBulkDelete(false, '')} variant="secondary">CANCEL</Button>
                            <Button onClick={this.deleteBulk} variant="primary">YES</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="md" show={this.state.confirmDeleteAll} onHide={() => this.confirmDeleteAll(false, '')}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Delete All User Group
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to Delete All User Group?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.confirmDeleteAll(false, '')} variant="secondary">CANCEL</Button>
                            <Button onClick={this.deleteAll} variant="primary">YES</Button>
                        </Modal.Footer>
                    </Modal>
                </Card.Body>
            </Card>
        );
    }
}


export default BulkGroupUpdation;