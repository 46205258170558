import React, { Component } from 'react';
import { Button, Modal, Card, Row, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { isEmpty } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from 'js-cookie';

import "./index.css";
class CampaignManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			dialerList: [],
            campaignList: [],
            selectedDialers: [],
            selectedCampaigns: [],
            confirmEditCampaigns: false,
            runningCampaignList: [],
            locationDialerCampaignList: [],
            availableCampaigns: [],
            initiallySelectedCampaigns: [],
            confirmRemoveCampaign: false,
            campaignToRemove: null,
            confirmAddCampaign: false,
            campaignToAdd: null,
            selectedEditCampaignLocation : '',
            selectedEditCampaignDialerId : '',
            sizePerPage: 10
		}
	}
	componentDidMount() {
		this.loadData();
        const savedSizePerPage = Cookies.get('manageCampSupAdmListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}

    loadData = async () => {
		try {
			const dialerList = await this.getDialer();
            await this.getDialerCampaigns(dialerList);
		} catch (err) {
			console.log('Error in fetching data', err);
		}
	}

    getDialer = async () => {
		const apiPath = this.props.app.apiPath;
        const response = await Fetcher.get(apiPath + '/api/obd/dialers');
        this.setState({
            dialerList: response
        })
        return response;
	}


	getDialerCampaigns = async (dialerList) => {
		const apiPath = this.props.app.apiPath;
		return Fetcher.get(apiPath + '/api/obd/get/campaign/allocation')
			.then(res => {
                // const dummyData = {
                //     "ahmedabad":{
                //         "1":{
                //             "500293":{
                //                  "cid":"500293",
                //                  "cmpName":"test_2000_3"
                //             }
                //         }
                //         },
                //     "Bangalore":{
                //        "2":{
                //              "500100":{
                //                   "cid":"500100",
                //                   "cmpName":"w;fkhwwd"
                //                  },
                //              "500136":{
                //                   "cid":"500136",
                //                   "cmpName":"dkldlasjcasxca"
                //                  }
                //            } ,
                //            "3":{
                //             "500122":{
                //                  "cid":"500122",
                //                  "cmpName":"27"
                //                 },
                //             "500293":{
                //                  "cid":"500293",
                //                  "cmpName":"test_2000_3"
                //             }
                //           } 
                //          }  
                //  }
                if (res.message === "Error While hitting url") {
                    Notify.notify(res.message);
                    return;
                }
                const campaignList = this.transformDialerData(res, dialerList);
                const selectedDialers = Object.values(res).flatMap(location =>
                    Object.keys(location).map(id => parseInt(id))
                );
				this.setState({
                    campaignList: campaignList,
                    selectedDialers: selectedDialers,
                    locationDialerCampaignList: res
                });
			})
			.catch(err => { console.log('Error in fetching Dialer Campaigns', err) });
	}

    transformDialerData = (data, dialerList) => {
        const campaignList = [];
    
        Object.keys(data).forEach(location => {
            const dialers = data[location];
    
            Object.keys(dialers).forEach(dialerId => {
                const campaigns = dialers[dialerId];
                const dialerName = dialerList.find(dialer => dialer.dialerId.toString() === dialerId)?.dialerName || `Dialer ${dialerId}`;
                const campaignNames = Object.values(campaigns).map(campaign => campaign.cmpName);
    
                if (campaignNames.length === 0) {
                    campaignNames.push('No Campaigns Assigned');
                }
    
                campaignList.push({
                    location,
                    dialerName,
                    dialerId,
                    campaignList: campaignNames
                });
            });
        });
    
        return campaignList;
    };
    

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

    getRunningCampaigns = async () => {
		const apiPath = this.props.app.apiPath;
        const userId = this.props.app.userId;
        const response = await Fetcher.get(apiPath + '/api/obd/get/running/campaigns/'+ userId);
        this.setState({
            runningCampaignList: response
        })
        return response;
	}

    editCampaigns = async (status, location, dialerId) => {
		this.setState({
			confirmEditCampaigns: status,
            selectedEditCampaignLocation : location,
            selectedEditCampaignDialerId : dialerId,
            selectedCampaigns: [],
            initiallySelectedCampaigns: [],
            availableCampaigns: [] 
		});
        
		if(status){
            const runningCampaignList = await this.getRunningCampaigns();
            const selectedDialers = [parseInt(dialerId)]
            const selectedCampaigns = [];
            for (let dialerId of selectedDialers) {
                const campaigns = this.state.locationDialerCampaignList[location][dialerId];
                for (let campaignId in campaigns) {
                    selectedCampaigns.push(campaignId);
                }
            }
           
            const availableCampaigns = [];
            const dialerCampaigns = this.state.locationDialerCampaignList[location][dialerId];
            for (let campaignId in dialerCampaigns) {
                const campaign = dialerCampaigns[campaignId];
                if (!runningCampaignList.some(runningCampaign => runningCampaign.campaignId === campaign.cid)) {
                    availableCampaigns.push(campaign);
                }
            }
            const availableCampaignIds = availableCampaigns.map(campaign => campaign.cid);
            const selectedCampaignIds = selectedCampaigns.filter(campaignId => availableCampaignIds.includes(campaignId) && runningCampaignList.some(runningCampaign => runningCampaign.campaignId.toString() === campaignId));
            runningCampaignList.forEach(runningCampaign => {
                const existingCampaign = availableCampaigns.find(campaign => campaign.cid.toString() === runningCampaign.campaignId.toString());
                if (!existingCampaign) {
                    if (!availableCampaigns.some(campaign => campaign.cid === runningCampaign.campaignId)) {
                        availableCampaigns.push({
                            cid: runningCampaign.campaignId,
                            cmpName: runningCampaign.campaignName,
                        });
                    }
                }
            });
            this.setState({ 
                selectedCampaigns: selectedCampaignIds,
                initiallySelectedCampaigns: selectedCampaigns,
                availableCampaigns: availableCampaigns 
            });
		}

	}

    handleCampaignSelect = async (selectedValue) => {
        const { selectedCampaigns, initiallySelectedCampaigns } = this.state;
        if (selectedValue === "") {
            this.setState({
                selectedCampaigns: [],
            });
        } else {
            if (selectedCampaigns.includes(selectedValue)) {
                this.setState({
                    confirmRemoveCampaign: true,
                    campaignToRemove: selectedValue,
                });
            } else {
                this.setState({
                    confirmAddCampaign: true,
                    campaignToAdd: selectedValue,
                });
            }
        }
    }
    
    
    removeCampaign = () => {
        const { selectedCampaigns, locationDialerCampaignList, selectedEditCampaignLocation, selectedEditCampaignDialerId, campaignToRemove } = this.state;
        let dataToSend = [];
    
        selectedCampaigns.forEach(campaignId => {
            if (locationDialerCampaignList[selectedEditCampaignLocation] && locationDialerCampaignList[selectedEditCampaignLocation][selectedEditCampaignDialerId]) {
                const campaigns = locationDialerCampaignList[selectedEditCampaignLocation][selectedEditCampaignDialerId];
                if (campaigns[campaignToRemove]) {
                    dataToSend = {
                        campaignId : campaigns[campaignToRemove].cid,
                        campaignName: campaigns[campaignToRemove].cmpName,
                        location: selectedEditCampaignLocation,
                        dialerId: selectedEditCampaignDialerId
                    };
                }
            }
        });

        if (Object.keys(dataToSend).length === 0){
            const selectedCmp = this.state.availableCampaigns.find(campaign => campaign.cid.toString() === campaignToRemove);
            dataToSend = {
                campaignId: campaignToRemove,
                campaignName: selectedCmp.cmpName,
                location: selectedEditCampaignLocation,
                dialerId: selectedEditCampaignDialerId
            };
        }
    
        return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/remove', {
            headers: { "Content-type": "application/json" },
            method: 'POST',
            body: JSON.stringify(dataToSend)
        })
        .then(res => {
            if (res.message === 'Parameters Incorrect') {
            this.state.confirmEditCampaigns = false
            Notify.notify('Update Failed');
            } else {
                this.state.confirmEditCampaigns = false
                Notify.notify(res.message);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        })
        .catch(err => {
            console.log('Error in removing campaigns', err);
            Notify.notify('Error in removing campaigns');
        });
    }
    
    addCampaign = () => {
        const { selectedCampaigns, locationDialerCampaignList, selectedEditCampaignLocation, selectedEditCampaignDialerId, campaignToAdd } = this.state;
        let dataToSend = {};
    
        selectedCampaigns.forEach(campaignId => {
            if (locationDialerCampaignList[selectedEditCampaignLocation] && locationDialerCampaignList[selectedEditCampaignLocation][selectedEditCampaignDialerId]) {
                const campaigns = locationDialerCampaignList[selectedEditCampaignLocation][selectedEditCampaignDialerId];
                if (campaigns[campaignToAdd]) {
                    dataToSend = {
                        campaignId,
                        campaignName: campaigns[campaignToAdd].cmpName,
                        location: selectedEditCampaignLocation,
                        dialerId: selectedEditCampaignDialerId
                    };
                }
            }
        });
        
        if (Object.keys(dataToSend).length === 0){
            const selectedCmp = this.state.availableCampaigns.find(campaign => campaign.cid.toString() === campaignToAdd);
            dataToSend = {
                campaignId: campaignToAdd,
                campaignName: selectedCmp.cmpName,
                location: selectedEditCampaignLocation,
                dialerId: selectedEditCampaignDialerId
            };
        }
        return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/add', {
            headers: { "Content-type": "application/json" },
            method: 'POST',
            body: JSON.stringify(dataToSend)
        })
        .then(res => {
            if (res.message === 'Parameters Incorrect') {
                this.state.confirmEditCampaigns = false
                Notify.notify('Update Failed');
                } else {
                    this.state.confirmEditCampaigns = false
                    Notify.notify(res.message);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
        })
        .catch(err => {
            console.log('Error in adding campaigns', err);
            Notify.notify('Error in adding campaigns');
        });
    }
    
    handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('manageCampSupAdmListsizePerPage', newSizePerPage);
	}

	render() {
		const campaignList = this.state.campaignList;
		var columns;
		
			columns = [
                {
                    dataField: 'location',
                    text: 'Location',
                    sort: true,
                    searchable: true
                },
                {
                    dataField: 'dialerName',
                    text: 'Dialer Name',
                    sort: true,
                    searchable: true
                },
				{
					dataField: 'dialerId',
					text: 'Dialer Id',
					sort: false,
					hidden: true
				},
				{
                    dataField: 'campaignList',
                    text: 'Campaign List',
                    sort: true,
                    searchable: true,
                    formatter: (cellContent, row) => (
                        <Dropdown>
                            <Dropdown.Toggle variant="success" className="btn-sm" id="dropdown-basic">
                                Campaign List
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {row.campaignList.map((campaign, index) => (
                                    <Dropdown.Item key={index}>{campaign}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                },
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false,
                    formatter: (cellContent, row) => (
                        <a className="edit-dialer" key={`${row.dialerId}-allocate-api-cli`}  onClick={(e) => { this.editCampaigns(true, row.location, row.dialerId) }} href="#"><i className="fa fa-edit"></i></a>
                    )
				},];
		
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'dialerId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: campaignList.length ?? 0,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

        let campaignDropdown = '';
        if (!isEmpty(this.state.availableCampaigns)) {
            campaignDropdown = this.state.availableCampaigns.map((campaign, index) => {
                return <option value={campaign.cid} key={`campaignId${index}`} >{campaign.cmpName}</option>
            });
        } else {
            campaignDropdown = <option value="">No Campaign found</option>
        }

	
		return (
			<Card>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}
				<Card.Header>Campaign List</Card.Header>
				<Card.Body>
					<div className="widget-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.dialerList ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="dialerId"
										data={campaignList}
										columns={columns}
										search
										bootstrap4

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/*<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/add-cli`}>Allocate CLI</Link>*/}
													
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} hover condensed wrapperClasses="table-responsive" classes="mt-3  widgetlist-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
													<PaginationListStandalone {...paginationProps} />
													<ReactTooltip />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
                    <Modal size="md" show={this.state.confirmEditCampaigns} onHide={() => this.editCampaigns(false, '')}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Update Campaigns
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form method="post">
                                <Row>
                                    <Col>
                                        <Form.Group className="form-group channels control-label">
                                            <Form.Label>Select Campaign</Form.Label>
                                            {this.state.availableCampaigns.length === 0 ? (
                                                <p>No campaigns available</p>
                                            ) : (
                                                this.state.availableCampaigns.map(campaign => (
                                                    <Form.Check
                                                        key={campaign.cid}
                                                        type="checkbox"
                                                        label={campaign.cmpName}
                                                        id={`campaign-${campaign.cid}`}
                                                        value={campaign.cid}
                                                        checked={this.state.selectedCampaigns.includes(campaign.cid)}
                                                        onChange={(e) => { this.handleCampaignSelect(e.target.value) }}
                                                    />
                                                ))
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    <Modal size="md" show={this.state.confirmRemoveCampaign} onHide={() => this.setState({ confirmRemoveCampaign: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Remove Campaign</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to remove this campaign?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ confirmRemoveCampaign: false })}>Cancel</Button>
                            <Button variant="primary" onClick={this.removeCampaign}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="md" show={this.state.confirmAddCampaign} onHide={() => this.setState({ confirmAddCampaign: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Campaign</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to add this campaign?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ confirmAddCampaign: false })}>Cancel</Button>
                            <Button variant="primary" onClick={this.addCampaign}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default CampaignManagement;